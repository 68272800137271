import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import useMetamaskAuth from '../../hook/useMetamaskAuth';
import { login } from '../../redux/actions/auth/auth';
import { createContractInstance } from '../../redux/actions/contract/contract';

const Login = () => {
  const dispatch = useDispatch();
  const [connect, walletAddress, walletBalance, isAuthenticated, signer] = useMetamaskAuth();

  const handelLogin = () => {
    connect();
  }

  useEffect(() => {
    let obj = {
      isAuthenticated: isAuthenticated,
      walletAddress: walletAddress,
      walletBalance: walletBalance
    }
    if (walletBalance) {
      dispatch(login(obj))
      dispatch(createContractInstance({ signer: signer }))
    }
  }, [walletAddress, isAuthenticated])

  return (
    <button onClick={handelLogin} className="px-5 py-2 font-medium rounded-md bg-white">Login</button>
  )
}

export default Login;
