import axios from "axios";
import store from "../redux/store/store";

import { setErrorMessageAction } from "../redux/actions/message/error";
import { setSuccessMessageAction } from "../redux/actions/message/success";

export const axiosClient = axios.create({
    baseURL: 'https://college-backend.utsavchatterjee.me/api/v1/',
    headers: {
        'x-userid': sessionStorage.getItem('userID') ? sessionStorage.getItem('userID') : null
    }
});

axiosClient.interceptors.response.use(res => {
    store.dispatch(setSuccessMessageAction({ message: res.data.info }))
    return res;
}, err => {
    store.dispatch(setErrorMessageAction({ message: err.response.data.message }))
    throw err;
});