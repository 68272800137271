import React from 'react';

const FormLeftPicture = () => {
  return (
    <div className="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
      <img
        src={'/assets/nftmintpage.jpg'}
        className="w-full"
        alt="Sample image"
      />
    </div>
  );
}

export default FormLeftPicture;
