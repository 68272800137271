import { axiosClient } from "../axiosClient";

const upload = async(dataObj, address) => {
    return await axiosClient.post(`storage/upload/id=${address}`, {
        music_filename: dataObj.musicFileName,
        music_fileobj: dataObj.musicObj,
        music_cover_filename: dataObj.musicCoverFileName,
        music_cover_fileobj: dataObj.musicCoverObj,
        artist_name: dataObj.artistName,
        desc: dataObj.alongStory
    })
}

export default upload;