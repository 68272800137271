import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import { login } from "../redux/actions/auth/auth";
import { createContractInstance } from "../redux/actions/contract/contract";

const useMetamaskAuth = () => {
    const { ethereum } = window;
    const [walletAddress, setWalletAddress] = useState(null);
    const [walletBalance, setWalletBalance] = useState(null);
    const [isAuthenticated, setisAuthenticated] = useState(false);
    const [signer, setSigner] = useState(null);
    const dispatch = useDispatch();

    const sendStore = (isAuthenticated, walletAddress, walletBalance) => {
        let obj = {
            isAuthenticated: isAuthenticated,
            walletAddress: walletAddress,
            walletBalance: walletBalance,
        };
        dispatch(login(obj));
    }

    const connectMetaMask = async() => {
        try {
            const res = await ethereum.request({ method: "eth_requestAccounts" });
            const walletAddress = res[0];
            setWalletAddress(walletAddress);
            await fetchWalletBalance(walletAddress);
            sessionStorage.setItem("userID", walletAddress);
            setisAuthenticated(true);
        } catch (err) {
            setisAuthenticated(false);
            setWalletBalance(null);
            setWalletAddress(null);
            console.log("err:", err);
        }
    }

    const fetchWalletBalance = async walletAddress => {
        try {
            const res = await ethereum.request({
                method: "eth_getBalance",
                params: [walletAddress, "latest"]
            });
            //console.log("res:", res);
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send('eth_requestAccounts', []);
            const signers = await provider.getSigner()
            setSigner(signers)
            const walletBalancs = ethers.utils.formatEther(res);
            //console.log(walletBalancs)
            setWalletBalance(walletBalancs);
            sendStore(true, walletAddress, walletBalancs)
            dispatch(createContractInstance({ signer: signers }))
        } catch (err) {
            console.log("err:", err);
            sessionStorage.clear();
            //window.location.reload();
        }
    }

    useEffect(() => {
        if (ethereum) {
            ethereum.on("accountsChanged", changedAddress => {
                fetchWalletBalance(changedAddress[0])
                    .then((res) => {
                        sessionStorage.setItem("userID", changedAddress[0])
                    })
            });
        } else {
            setisAuthenticated(false)
            setWalletBalance(null)
            setWalletAddress(null)
        }
    }, []);

    const connect = () => {
        connectMetaMask();
    }

    useMemo(() => {
        if (sessionStorage.getItem("userID")) {
            fetchWalletBalance(sessionStorage.getItem("userID"))
                .then(() => {
                    setWalletAddress(sessionStorage.getItem("userID"));
                    setisAuthenticated(true);
                })
        } else {
            setisAuthenticated(false)
            setWalletBalance(null)
            setWalletAddress(null)
        }
    }, []);

    return [connect, walletAddress, walletBalance, isAuthenticated, signer];
}

export default useMetamaskAuth;