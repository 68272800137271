import { createReducer } from "@reduxjs/toolkit";
import { setLoadingAction } from "../../actions/loading/loading";


const initialState = {
    isLoading: false,
};

export const loadingReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLoadingAction, (state, action) => {
            state.isLoading = action.payload.is;
        })
});