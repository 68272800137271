import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


import Avatar from '../Avatar/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { visibility } from '../../redux/actions/musicplayer/playerVisibility';
import { updateMusic } from '../../redux/actions/musicplayer/playMusic';
import { ethers } from 'ethers';
import Buy from '../buyNft';

function SellRunningDrops({nfts}) {
  
console.log(nfts)
  const dispatch=useDispatch()
  console.log(nfts[0]?.tokenId)
  const contracts = useSelector(state => state.contractReducer);
  
  const getOwner=async()=>{
    const res=await contracts.nftcontract?.ownerOf(nfts[0]?.tokenId._hex)
    console.log(res)

    const res2=await contracts.businesscontract?.getOneNFTbuyingHist(nfts[0]?.tokenId._hex)
    console.log(res2)
  }
  // getOwner()

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-x-4 justify-between items-center">
        <div className="text-2xl font-medium">Already Sell is Started !</div>
        {
          window.screen.width >= 640 ?
            <div className="px-2 font-medium select-none cursor-pointer rounded-lg outline outline-1 text-slate-800 hover:bg-slate-200 outline-slate-200">See All</div> :
            <div className="self-start">
              <i className="fa-solid fa-chevron-right text-xl"></i>
            </div>
        }
      </div>
      <div className="slider-ud w-full">
        <Swiper
          spaceBetween={20}
          navigation={window.screen.width > 640 ? true : false}
          modules={[Navigation]}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 4,
            },
            1600: {
              slidesPerView: 5,
            },
          }}
          className="swiperjs-slider-ud"
        >
          {
            nfts?.length ? <>

{
            nfts?.map((i, k) => {
              return (
                <SwiperSlide key={k}>
                  <div onClick={()=>{
                      dispatch(visibility({ state: true }));
                      dispatch(updateMusic({ music: (i.Uri).split(" ")[0] }));
                  }} className={`slide relative w-full h-[480px] xs:h-[320px] sm:h-[360px] rounded-xl overflow-hidden before:hover:scale-125 before:duration-300`} style={{ backgroundImage: `url(${(i.Uri).split(" ")[1]})`,backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover",cursor:"pointer",filter:"brightness(50%)" }}>
                    <div className="h-full flex flex-col justify-between">
                      <div className="px-1.5 py-3 flex justify-end">
                        <div className="px-2 py-0.5 pt-1 font-semibold xs:text-[10.5px] sm:text-[12.5px] rounded-full backdrop-blur-lg text-white bg-black/30">Ends : {new Date(parseInt(i?.sell_ending_timestamp._hex)).toLocaleString()}</div>
                        
                      </div>
                      
                      <div className="px-2">
                        <Buy itemid={i?.tokenId._hex} price={i?.price._hex}/>
                        <div className="px-1 mb-3 text-white">
                          <h1 className="w-[80%] text-xs truncate text-slate-300/60">{i.meta.artist}</h1>
                          <h1 className="font-medium text-[0.79rem] sm:text-xl truncate">{(i.Uri.split(" ")[0].split("/")[i.Uri.split(" ")[0].split("/").length-1]).split(".")[0].toUpperCase()}</h1>
                          <h1 className="w-[80%] text-xs truncate text-white">Price : {ethers.utils.formatEther((i.price._hex))} ETH</h1>
                        </div>
                        <div className="mb-4 flex justify-between items-center rounded-md">
                          <div className="w-[50%] flex gap-x-2 items-center">
                            <div className="">
                              <Avatar
                                sizeInPixel={25}
                                skeletonSizeInPixel={15}
                                twRounded={"rounded-full"}
                                skeletonTwRounded={"before:rounded-full"}
                                avatar={"after:bg-[url(https://media.istockphoto.com/id/1209654046/vector/user-avatar-profile-icon-black-vector-illustration.jpg?s=612x612&w=0&k=20&c=EOYXACjtZmZQ5IsZ0UUp1iNmZ9q2xl1BD1VvN6tZ2UI=)]"}
                              />
                            </div>
                            <div className="text-xs font-semibold truncate text-white">{i.meta.artist}</div>
                          </div>
                          <div className="text-[12px] text-gray-400/70">minted {parseInt(i.number_of_editions._hex)} edition</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })
          }
            </>:<h1>no data</h1>
          }
        </Swiper>
      </div>
    </div>
  );
}

export default SellRunningDrops;
