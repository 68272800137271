import { ethers } from "ethers";
import fetch from "../server/fetchFile";
import store from "../../redux/store/store";
import { setLoadingAction } from "../../redux/actions/loading/loading";
import { setSuccessMessageAction } from "../../redux/actions/message/success";



const minting = async(uri, nftContract, businessContract, dataobj) => {
    store.dispatch(setLoadingAction({ isLoading: true }))
    const data = await fetch(uri);

    if (data !== null) {
        console.log(data.data.data.music_file_uri)
        const file_uri = data.data.data.music_file_uri + " " + data.data.data.music_cover_uri
        await (await nftContract.mint(uri)).wait()
        store.dispatch(setSuccessMessageAction({ message: "nft created!" }))
        console.log('ok')
        const id = await nftContract.tokenCount()
        console.log(id)
        await (await nftContract.setApprovalForAll(businessContract.address, true)).wait()
        store.dispatch(setSuccessMessageAction({ message: "nft approved!" }))
        return await (await businessContract.makeItem(nftContract.address, file_uri, id, ethers.utils.parseEther(dataobj.price.toString()), dataobj.sellingTypeAuction, dataobj.sellStartTimeStamp.toString(), dataobj.sellEndTimeStamp.toString(), dataobj.editions, dataobj.artistName, dataobj.description)).wait()
    }
}

export default minting;