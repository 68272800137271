import { createReducer } from "@reduxjs/toolkit";
import { visibility } from "../../actions/musicplayer/playerVisibility";
import { updateMusic } from "../../actions/musicplayer/playMusic";
import { Howl, Howler } from 'howler';

const initialState = {
    music: '',
    isEnable: false,
}

export const playerReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(visibility, (state, action) => {
            state.isEnable = action.payload.state
        })
        .addCase(updateMusic, (state, action) => {
            state.music = action.payload.music

        })
});