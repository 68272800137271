import React from 'react';
import Form from '../../component/mint/Form';

const Mint = () => {
  return (
    <>
      <main>
        <Form />
      </main>
    </>
  );
}

export default Mint;
