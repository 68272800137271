import React, { useEffect, useMemo, useState } from 'react';
import Carousel from '../../component/home/Carousel';
import SearchBar from '../../component/searchbar/SearchBar';
import SellRunningDrops from '../../component/home/SellRunningDrops';
import UpcomingDrops from '../../component/home/UpcomingDrops';
import RootContext from '../../context/RootContext';
import Login from '../../component/Login/Login';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from '../../component/Avatar/Avatar';
import getAllNft from '../../service/nft/getAllNft';
import { saveAllNFT } from '../../redux/actions/nft/nft';
import getHourDiff from '../../helper/getHourDiffFromTimestamp';
import getSellnotStartNFT from '../../service/nft/sellNotStarted';
import getSellStartNFT from '../../service/nft/sellStarted';
import AuctionsDrops from '../../component/home/AuctionsDrops';


function Home() {
  const dispatch = useDispatch();
  const [carouselData, setCarouselData] = useState([]);
  const [upcomingDropsData, setUpcomingDropsData] = useState([]);
  const [sellRunningData, setSellRunningData] = useState([]);

  const [backgroundImage, setBackgroundImage] = useState(null);
  const [landingPageBefore, setLandingPageBefore] = useState(null);
  const backgroundImages = [
    "https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg?cs=srgb&dl=pexels-james-wheeler-414612.jpg&fm=jpg",
    "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg?size=626&ext=jpg",
    "https://img.freepik.com/premium-photo/majestic-mountain-range-sunrise-with-small-lake-lone-tree-foreground_685067-2689.jpg?size=626&ext=jpg&ga=GA1.1.1038686110.1692890733&semt=ais",
    "https://img.freepik.com/premium-photo/random-best-photo_865967-223105.jpg?size=626&ext=jpg&ga=GA1.1.1038686110.1692890733&semt=ais",
    "https://img.freepik.com/free-photo/nature-beauty-captured-tranquil-scene-mountain-peak-reflection-generative-ai_188544-12745.jpg?size=626&ext=jpg&ga=GA1.1.1038686110.1692890733&semt=ais"
  ];

  const nftdata = useSelector(state => state.nftReducer.allNfts);
  const contracts = useSelector(state => state.contractReducer);
  const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);

  const getUri = async (ele, nftcontract) => {
    return await nftcontract.tokenURI(parseInt(ele?.tokenId));
  }

  const updateCarouselData = async (data) => {
    // console.log(getHourDiff(new Date().getTime(),parseInt(data?.sell_starting_timestamp._hex)))
    if ((parseInt(data?.sell_starting_timestamp._hex) > Date.now()) && (getHourDiff(
      new Date().getTime(),
      parseInt(data?.sell_starting_timestamp._hex)
    )<2) && (!carouselData?.find(e => e.tokenId._hex === data.tokenId._hex)) && carouselData.length<=5) {
      setCarouselData(p=>[data,...p]);
    }
  }


  const updateUpcomingDropsData = async (data) => {
    if ((parseInt(data?.sell_starting_timestamp._hex) > Date.now()) && (!upcomingDropsData?.find(e => e.tokenId._hex === data.tokenId._hex))) {
      setUpcomingDropsData(p=>[data,...p]);
    }
  }

  const updateSellRunningData = async (data) => {
    if ((parseInt(data?.sell_starting_timestamp._hex) < Date.now()) && (parseInt(data?.sell_ending_timestamp._hex) > Date.now()) && (!sellRunningData?.find(e => e.tokenId._hex === data.tokenId._hex))) {
      setSellRunningData(p=>[data,...p]);
    }
  }



  useMemo(() => {
    try {
      if (contracts.businesscontract !== null) {
        getAllNft(contracts.businesscontract)
          .then((res) => {
            //console.log("all : ",res)
            dispatch(saveAllNFT({ nfts: res }))
          })
          .catch(err=>console.log(err))
        
          getSellnotStartNFT(contracts.businesscontract)
          .then(res=>{
            console.log("sell not started : ",res)
          })
          .catch(err=>console.log(err))
  
          getSellStartNFT(contracts.businesscontract)
          .then(res=>{
            console.log("sell started : ",res)
          })
          .catch(err=>console.log(err))
      }
    } catch (error) {
      console.log(error)
    }
    
  }, [contracts.businesscontract]);

  console.log('nft data : ',nftdata)

  useMemo(() => {
    try {
      if (nftdata?.length) {
        nftdata?.map((i) => {
          updateCarouselData(i);
          updateUpcomingDropsData(i);
          updateSellRunningData(i);
        })
      }
    } catch (error) {
      console.log(error)
    }
    
  }, [nftdata]);
  

  useEffect(() => {
    let tempLandingPageBefore = null;

    if (!landingPageBefore) {
      console.log("dhukche");

      Array.from(document.styleSheets).filter(e => {
        let temp = Array.from(e.cssRules).find(ele => {
          return ele.selectorText === ".landing-page::before";
        });

        if (temp) {
          setLandingPageBefore(temp);
          tempLandingPageBefore = temp;
        }

        return temp;
      });
    }

    if (landingPageBefore)
      landingPageBefore.style.backgroundImage = backgroundImage ? backgroundImage : `url(${backgroundImages[0]})`;
    else
      tempLandingPageBefore.style.backgroundImage = backgroundImage ? backgroundImage : `url(${backgroundImages[0]})`;
  }, [backgroundImage]);

  let value = {
    carouselData,
    backgroundImages,
    backgroundImage, setBackgroundImage
  };

  return (
    <RootContext.Provider value={value}>
      <div className="landing-page relative mt-16 sm:mt-0 overflow-x-hidden before:h-[215px] sm:before:h-[240px] before:duration-300 before:scale-150">
        <div className="container mx-auto">
          <div className="relative section-carousel w-full">
            <div className="py-5 mx-6 flex flex-col gap-y-4">
              <div>
                <div className="flex justify-between items-center gap-x-12">
                  <div className="w-full"><SearchBar /></div>
                  {
                    isAuthenticated ?
                      <div className="hidden sm:flex gap-x-4">
                        <Link to={"/mint-nft"} className="px-5 py-2 font-medium rounded-md bg-white">Upload</Link>
                        <div className="">
                          <Avatar
                            twRounded={"rounded-md"}
                            skeletonTwRounded={"before:rounded-md"}
                            // avatar={"https://www.sound.xyz/_next/image?url=https%3A%2F%2Fd16zqvkniqf1rn.cloudfront.net%2Fplaceholders%2Favatars%2Fv2%2F7.jpg&w=64&q=75"}
                            avatar={"after:bg-[url(https://www.sound.xyz/_next/image?url=https%3A%2F%2Fd2i9ybouka0ieh.cloudfront.net%2Fartist-uploads%2F2082be4d-5303-42a3-8b44-9d9bcd9b76b8%2FRELEASE_COVER_IMAGE%2F7715268-newImage.png&w=384&q=75)]"}
                          />
                        </div>
                      </div> :
                      <div className="hidden sm:block">
                        <Login />
                      </div>
                  }
                </div>
              </div>
              <div className="font-medium text-slate-200">
                <span>$5,731,177 Earned by artists from fans on Sound</span>
              </div>
              <div className="">
                <Carousel nfts={carouselData}/>
              </div>
            </div>
          </div>
          <div className="px-6 py-5">
            <UpcomingDrops nfts={upcomingDropsData}/>
          </div>
          <div className="px-6 py-5">
            <SellRunningDrops nfts={sellRunningData}/>
          </div>
          <div className="px-6 py-5">
            <AuctionsDrops />
          </div>
        </div>
      </div>
    </RootContext.Provider>
  );
}

export default Home;
