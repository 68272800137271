import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../reducers/auth/auth";
import { contractReducer } from "../reducers/contract/contract";
import { nftReducer } from "../reducers/nft/nft";
import { messageReducer } from "../reducers/message/message";
import { playerReducer } from "../reducers/musicplayer/player";
import { loadingReducer } from "../reducers/loading/loading";


const store = configureStore({
    reducer: {
        authReducer: authReducer,
        contractReducer: contractReducer,
        nftReducer: nftReducer,
        messageReducer: messageReducer,
        playerReducer: playerReducer,
        loadingReducer: loadingReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;