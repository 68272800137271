import { createReducer } from "@reduxjs/toolkit";
import { createContractInstance } from "../../actions/contract/contract";

import { ethers } from "ethers";
import nftabi from '../../../util/abi/nftAbi.json'
import businessabi from '../../../util/abi/businessAbi.json'
import { businessContractAddress, nftContractAddress } from "../../../util/blockAddress/address";

const initialState = {
	nftcontract: null,
	businesscontract: null
}

export const contractReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(createContractInstance, (state, action) => {
			state.nftcontract = new ethers.Contract(nftContractAddress, nftabi.abi, action.payload.signer);
			state.businesscontract = new ethers.Contract(businessContractAddress, businessabi.abi, action.payload.signer);
		})
});
