import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAllTransactions,getBal } from '../../service/nft/getAllTransaction';
import { ethers } from 'ethers';

function Transaction() {

    const contracts = useSelector(state => state.contractReducer);
    const[transactions,setTransactions]=useState([]);

    useEffect(()=>{
        try {
            if (contracts.businesscontract !== null) {
                getAllTransactions(contracts.businesscontract)
                .then(res=>{
                    console.log(res);
                    setTransactions(res)
                })
                .catch(err=>console.log(err))

                // getBal(contracts.businesscontract)
                // .then(res=>{
                //     console.log(res)
                // })
                // .catch(err=>console.log(err))
            }
        } catch (error) {
            console.log(error)
        }
    },[contracts.businesscontract])
    return (
        <>
            {/* component */}
            <div className="flex min-h-screen items-center justify-center">
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white shadow-md rounded-xl">
                        <thead>
                            <tr className="bg-blue-gray-100 text-gray-700">
                                <th className="py-3 px-4 text-left">Timestamp</th>
                                <th className="py-3 px-4 text-left">Token</th>
                                <th className="py-3 px-4 text-left">Amount</th>
                                <th className="py-3 px-4 text-left">Buyer</th>
                                <th className="py-3 px-4 text-left">Seller</th>
                            </tr>
                        </thead>
                        <tbody className="text-blue-gray-900">
                            {
                                transactions?.length &&
                                transactions.map((v,i)=>
                                <tr className="border-b border-blue-gray-200" key={i}>
                                    <td className="py-3 px-4">{parseInt(v?.buying_date)}</td>
                                    <td className="py-3 px-4">{parseInt(v?.tokenId)}</td>
                                    <td className="py-3 px-4">{ethers.utils.formatEther((v?.amount._hex))}ETH</td>
                                    <td className="py-3 px-4">{v?.to_buyer}</td>
                                    <td className="py-3 px-4">{v?.from_seller}</td>
                                </tr>
                                )
                            }
                            
                        </tbody>
                    </table>
                    <div className="w-full pt-5 px-4 mb-8 mx-auto ">
                        <div className="text-sm text-gray-700 py-1 text-center">
                            Made with{" "}
                            <a
                                className="text-gray-700 font-semibold"
                                href="https://www.material-tailwind.com/docs/html/table/?ref=tailwindcomponents"
                                target="_blank"
                            >
                                DAPP
                            </a>{" "}
                            by{" "}
                            <a
                                href="https://www.creative-tim.com?ref=tailwindcomponents"
                                className="text-gray-700 font-semibold"
                                target="_blank"
                            >
                                {" "}
                                RythmXchange Team
                            </a>
                            .
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Transaction