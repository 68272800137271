import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import MusicPlayer from '../component/musicplayer/MusicPlayer';
import Navigation from '../component/navigation/Navigation';
import useMetamaskAuth from '../hook/useMetamaskAuth';
import { axiosClient } from '../service/axiosClient';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessMessageAction } from '../redux/actions/message/success';
import { setErrorMessageAction } from '../redux/actions/message/error';
import LoadingToast from '../component/toasters/LoadingToaster';
import SuccessToaster from '../component/toasters/SuccessToaster';
import ErrorToaster from '../component/toasters/ErrorToaster';


function RootLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const connect = useMetamaskAuth();
  const dispatch=useDispatch();
  const success=useSelector(state=>state.messageReducer.success);
  const error=useSelector(state=>state.messageReducer.error)

  useEffect(()=>{
    // axiosClient.get()
    // .then(res=>{
    //   console.log(res.data)
    //   dispatch(setSuccessMessageAction(
    //     {message:res.data.message}
    //   ))
    // })
    // .catch(err=>{
    //   dispatch(setErrorMessageAction(
    //     {message:err.response.data.message}
    //   ))
    // })
    
  },[])

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
    }
  }, [location]);

  return (
    <>
      <Navigation RootComponent={Outlet} />
      <MusicPlayer />
      <LoadingToast/>
      {success?<SuccessToaster message={success}/> : error ? <ErrorToaster message={error}/> :<></>}
    </>
  )
}

export default RootLayout;
