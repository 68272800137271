import { createReducer } from "@reduxjs/toolkit";
import { saveAllNFT } from "../../actions/nft/nft";

const initialState = {
	allNfts: null
}

export const nftReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(saveAllNFT, (state, action) => {
			state.allNfts = action.payload.nfts
		})
});
