import { ethers } from "ethers";

const buyNft = async(contract, itemId, price) => {
    // const item_price = await contract.getTotalPrice(itemId);
    if (price) {
        const res = await contract.purchaseItem(itemId, { value: price }); // wei price
        return res;
    }
}

export default buyNft;