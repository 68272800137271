import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";

const LoadingToast = () => {

	const toastId = useRef(null)
    const isLoading=useSelector(state=>state.loadingReducer.isLoading)

	useEffect(() => {
		if (isLoading) {
			toastId.current = toast.loading('Please wait...', {
				isLoading: true,
				theme: "dark",
                position:"bottom-left"
			})
		}
		else {
			toast.update(toastId.current, {
				isLoading: false,
				autoClose: 600
			})
		}
	}, [isLoading])

	return (
		<ToastContainer />
	)
}

export default LoadingToast;