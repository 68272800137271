import React, { useEffect, useState, createContext } from 'react';
import {
  Input,
  Ripple,
  initTE,
} from "tw-elements";
import FormLeftPicture from './FormLeftPicture';
import FormInputs from './FormInputs';
import { useLoaderData } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import minting from '../../service/nft/mintNft';
import getAllNft from '../../service/nft/getAllNft';
import { saveAllNFT } from '../../redux/actions/nft/nft';
import uploadFile from '../../service/server/uploadFile';
import { setLoadingAction } from '../../redux/actions/loading/loading';
import { setSuccessMessageAction } from '../../redux/actions/message/success';


export const FormProvider = createContext();

const Form = () => {
  const ethConversion = useLoaderData();
  console.log(ethConversion)
  const dispatch = useDispatch();

  const address = useSelector(state => state.authReducer.walletAddress)
  const contracts = useSelector(state => state.contractReducer);
  

  const [data, setData] = useState({
    musicObj: null,
    musicFileName: '',
    artistName: '',
    musicCoverObj: null,
    musicCoverFileName: '',
    price: 0,
    sellingTypeAuction: false,
    sellStartTimeStamp: null,
    sellEndTimeStamp: null,
    editions: 0,
    description: '',
    alongStory:'',
  })

  console.log(data);

  useEffect(() => {
    initTE({ Input, Ripple });
  }, []);

  const process = async () => {
    uploadFile(data, address)
      .then((res) => {
        const uri = res.data.uri;
        return mintUri(uri);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const mintUri = async (uri) => {
    minting(uri, contracts.nftcontract, contracts.businesscontract, data)
      .then((res) => {
        dispatch(setLoadingAction({isLoading:false}))
        dispatch(setSuccessMessageAction({message:"nft minted!"}))
        getAllNft(contracts.businesscontract)
          .then((res) => {
            dispatch(saveAllNFT({ nfts: res }))
            dispatch(setLoadingAction({isLoading:false}))
          })
          .catch(err=>{
            dispatch(setLoadingAction({isLoading:false}))
          })
      .catch(err=>{
        dispatch(setLoadingAction({isLoading:false}))
      })
      })
  }

  const uploadToBusinessContract = async () => {
    try {
      await process();
    } catch (error) {
      console.log(error)
    }
    
  }

  const provideValues = {
    uploadToBusinessContract: uploadToBusinessContract,
    setData: setData,
    data: data,
    usdToEth: parseFloat(ethConversion?.ETH),
  }

  return (
    <>
      <FormProvider.Provider value={provideValues}>
        <section className="h-screen">
          <div className="h-full">
            <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
              {/* Left column container with background*/}
              <FormLeftPicture />
              <div className="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12 ">
                {/* Right column container */}
                <FormInputs />
              </div>
            </div>
          </div>
        </section>
      </FormProvider.Provider>
    </>
  );
}

export const EthRates = async () => {
  const res = await fetch(`https://api.coinbase.com/v2/exchange-rates?currency=USD`)
  const data = await res.json()
  return data.data.rates;
}

export default Form;
