import React from 'react';
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';
import { useSelector } from 'react-redux';

const MusicPlayer = () => {
  const music = useSelector(state => state.playerReducer.music);
  const isMusicEnable = useSelector(state => state.playerReducer.isEnable);

  return (
    <div style={{ visibility: isMusicEnable ? "visible" : "hidden" }} className={`fixed z-10 bottom-0 w-full`}>
      <AudioPlayer
        autoPlay
        src={music}
        onPlay={e => console.log("onPlay")}
      />
    </div>
  );
}

export default MusicPlayer;
