import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Page404 from "../page/page404/Page404";
import Home from "../page/home/Home";
import RootLayout from "../layout/RootLayout";
import ProtectedRoute from "./protectedRoute";
import Mint from "../page/mint/Mint";
import { EthRates } from "../component/mint/Form";
import Transaction from "../page/home/Transaction";


const router = createBrowserRouter(
	
	createRoutesFromElements(
		<>
			<Route path="/" element={<RootLayout />}>
				<Route path="home" element={<Home />} />
				<Route path="mint-nft" loader={EthRates} element={<ProtectedRoute><Mint /></ProtectedRoute>} />
				<Route path="transaction" element={<Transaction />} />
				<Route path="*" element={<Page404 />} />
			</Route>
		</>
	)
);

export default router;
