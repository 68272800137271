import React from 'react';
import { useSelector } from 'react-redux';
import Page404 from "../page/page404/Page404";

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);


    if (!isAuthenticated) {
        return <Page404 / >
    }

    return children;
}

export default ProtectedRoute;