import React from 'react'
import buyNft from '../../service/nft/buyNft'
import { useSelector } from 'react-redux';

function Buy({itemid,price}) {
  const contracts = useSelector(state => state.contractReducer);

  const handleClick=()=>{
    buyNft(contracts.businesscontract,itemid,price)
    .then(res=>{
      console.log(res)
    })
    .catch(err=>console.log(err))
  }
  return (
    <button onClick={handleClick} type="button" class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">BUY</button>
  )
}

export default Buy