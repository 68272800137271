export const getAllTransactions = async(contract) => {
    const res = await contract.fetchAllBuyHist();
    return res;
}

export const getBal=async(contract,addr)=>{
    const bal=await contract.withdrawn('0xaB247ddf929E01F91AF7a84f57c02feaD4F7aBc0');
    return bal;
}

