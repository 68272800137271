import React, { useContext, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import RootContext from '../../context/RootContext';
import { useDispatch } from 'react-redux';
import { updateMusic } from '../../redux/actions/musicplayer/playMusic';
import { visibility } from '../../redux/actions/musicplayer/playerVisibility';
import { ethers } from 'ethers';
import Buy from '../buyNft';


function Carousel() {
  const context = useContext(RootContext);
  const dispatch = useDispatch();
  

  return (
    <div className="carousel">
      {context.carouselData?.length&&<Swiper
        onSlideChange={e => {
          context.setBackgroundImage(e.el.children[0].children[e.activeIndex].children[0].style.backgroundImage);
        }}
        slidesPerView={'auto'}
        spaceBetween={20}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="swiperjs-carousel"
      >
        {
          context.carouselData?.map((item, index) => {
            
            return (
              <SwiperSlide key={index} className="rounded-3xl bg-red-400">
                
                <div style={{ backgroundImage: `url(${(item.Uri).split(" ")[1]})` }} className="w-full h-[420px] rounded-3xl bg-cover">
                
                  <div className="spotlight rounded-3xl h-full w-[100%]">
                  {/* <Buy itemid={item?.tokenId._hex} price={item?.price._hex}/> */}
                    <div className="h-full px-5 md:px-10 flex justify-between">
                      <div className="w-full md:w-[55%] py-12 md:py-16 lg:py-0 flex items-end md:items-center">
                      <div className="px-2 py-0.5 pt-1 font-semibold xs:text-[10.5px] sm:text-[12.5px] rounded-full backdrop-blur-lg text-white bg-black/30">Starts : {new Date(parseInt(item?.sell_starting_timestamp._hex)).toLocaleString()}</div>
                        <div className="w-full flex gap-x-4">
                          <button onClick={()=>{
                            dispatch(visibility({ state: true }));
                            dispatch(updateMusic({ music: (item.Uri).split(" ")[0] }));
                            }} className="px-3 py-2 sm:px-4 sm:py-3 md:px-6 md:py-5 rounded-md bg-white">
                            <i className="fa-solid fa-play ml-0.5 text-xl sm:text-2xl md:text-3xl"></i>
                          </button>
                          <h1 className="font-extrabold truncate sm:text-2xl md:text-4xl text-white">{(item.Uri.split(" ")[0].split("/")[item.Uri.split(" ")[0].split("/").length-1]).split(".")[0].toUpperCase()}</h1>
                          <h3 className="font-extrabold truncate sm:text-2xl md:text-4xl text-white">{item.meta.artist}</h3>
                          <h1 className="w-[80%] text-xs truncate text-white">Price : {ethers.utils.formatEther((item.price._hex))} ETH</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })
        }
      </Swiper>}
    </div>
  );
}

export default Carousel;
