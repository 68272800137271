import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { useDispatch } from 'react-redux';
import { visibility } from '../../redux/actions/musicplayer/playerVisibility';
import { updateMusic } from '../../redux/actions/musicplayer/playMusic';
import { ethers } from 'ethers';

function UpcomingDrops({nfts}) {
  const dispatch=useDispatch()

  console.log(nfts)

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-x-4 justify-between items-center">
        <div className="text-2xl font-medium">Upcoming Drops</div>
        {
          window.screen.width >= 640 ?
            <div className="px-2 font-medium select-none cursor-pointer rounded-lg outline outline-1 text-slate-800 hover:bg-slate-200 outline-slate-200">See All</div> :
            <div className="self-start">
              <i className="fa-solid fa-chevron-right text-xl"></i>
            </div>
        }
      </div>
      <div className="flex gap-x-3">
        <button className="px-3 py-1 rounded-lg font-medium text-white/90 bg-[#262626]">Hot</button>
        <button className="px-3 py-1 rounded-lg text-slate-700 hover:bg-[#262626]/20 bg-slate-100">Following</button>
      </div>
      <div className="slider-ud w-full">
        <Swiper
          spaceBetween={20}
          navigation={window.screen.width > 640 ? true : false}
          modules={[Navigation]}
          breakpoints={{
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 4,
            },
            1600: {
              slidesPerView: 5,
            },
          }}
          className="swiperjs-slider-ud"
        >
          {
            nfts.length? <>
            
            {
            nfts?.map((i, k) => {
              return (
                <SwiperSlide key={k}>
                  <div onClick={()=>{
                      dispatch(visibility({ state: true }));
                      dispatch(updateMusic({ music: (i.Uri).split(" ")[0] }));
                  }} className={`slide relative w-full h-[480px] xs:h-[320px] sm:h-[360px] rounded-xl overflow-hidden before:hover:scale-125 before:duration-300 `} style={{ backgroundImage: `url(${(i.Uri).split(" ")[1]})`,backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"cover",cursor:"pointer",filter:"brightness(50%)" }}>
                    <div className="h-full flex flex-col justify-between">
                      <div className="px-1.5 py-3 flex justify-end">
                        <div className="px-2 py-0.5 pt-1 font-semibold xs:text-[10.5px] sm:text-[12.5px] rounded-full backdrop-blur-lg text-white bg-black/30">Starts : {new Date(parseInt(i?.sell_starting_timestamp._hex)).toLocaleString()}</div>
                      </div>
                      <div className="px-2">
                        <div className="px-1 mb-3 text-white">
                          <h1 className="w-[80%] text-xs truncate text-slate-300/60">{i.meta.artist}</h1>
                          <h1 className="font-medium text-[0.79rem] sm:text-xl truncate">{(i.Uri.split(" ")[0].split("/")[i.Uri.split(" ")[0].split("/").length-1]).split(".")[0].toUpperCase()}</h1>
                          <h1 className="w-[80%] text-xs truncate text-white">Price : {ethers.utils.formatEther((i.price._hex))} ETH</h1>
                        </div>
                        <button className="mb-2 py-2 w-full space-x-1 rounded-md font-semibold bg-white">
                          <span><i className="fa-solid fa-bell"></i></span>
                          <span>Get Notified</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })
          }
            </>:<h1>no data</h1>
          }
        </Swiper>
      </div>
    </div>
  );
}

export default UpcomingDrops;
