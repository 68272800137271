import { createReducer } from "@reduxjs/toolkit";
import { login, logout } from "../../actions/auth/auth";

const initialState = {
	isAuthenticated: false,
	walletAddress: null,
	walletBalance: null
}

export const authReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(login, (state, action) => {
			state.isAuthenticated = action.payload.isAuthenticated
			state.walletAddress = action.payload.walletAddress
			state.walletBalance = action.payload.walletBalance
		})
		.addCase(logout, (state, payload) => {
			state.isAuthenticated = false
			state.walletAddress = null
			state.walletBalance = null
		})
});